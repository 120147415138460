<template>
  <form-field :name="field.name">
    <v-autocomplete
      v-model="field.value"
      :items="items"
      :menu-props="{maxHeight:'250px'}"
      v-bind="field.props"
      combobox
      dense
      box
      hint="Select or type a new title"
      label="Enter title"
      persistent-hint
      required
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  data () {
    return {
      items: ['Mr', 'Mrs', 'Miss', 'Dato\'', 'Datin'],
    }
  },
  computed: {
    avatar: function () {
      for (const item of this.items) { if (item.avatar) return true }

      return false
    },
  },
}
</script>
